import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import './scss/conditional-image.scss';
import DEFAULT_IMAGE from 'images/default-images/icon-image-placeholder.svg';

const Image = ({ image, imageText, className, defaultImage, fixed }) => {
  const renderGatsbyImage = () => {
    if (fixed) {
      return <Img fixed={image} alt={imageText} className={className} />;
    }
    return <Img fluid={image} alt={imageText} className={className} />;
  };

  return (
    <>
      {image ? (
        renderGatsbyImage()
      ) : (
        <div className={`image-placeholder ${className}`}>
          <div className="image-placeholder__inner">
            <img
              src={defaultImage}
              alt={imageText}
              className="image-placeholder__img"
            />
          </div>
        </div>
      )}
    </>
  );
};

Image.defaultProps = {
  imageText: '',
  defaultImage: DEFAULT_IMAGE,
};

Image.propTypes = {
  fixed: PropTypes.bool,
  defaultImage: PropTypes.string,
  imageText: PropTypes.string,
  className: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object]),
};

export { Image };
